"use client";

import React, { useState } from 'react';
import PartnerCard from '@modules/partners/partnercard';
import BannerSkeleton from '@modules/skeletons/components/skeleton-banner';
import { Partner } from '@/lib/data';

interface PartnerCarouselProps {
  partners: Partner[];  // Ricevi i partner come props
}

const PartnerCarousel: React.FC<PartnerCarouselProps> = ({ partners }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  if (!partners || partners.length === 0) {
    // Se non ci sono partner o c'è un errore, mostra gli skeletons
    return (
      <div className="grid grid-cols-5 gap-4">
        {Array.from({ length: 5 }).map((_, index) => (
          <BannerSkeleton key={index} />
        ))}
      </div>
    );
  }

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 5, partners.length - 5));
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 5, 0));
  };

  return (
    <div className="relative w-full">
      <div className="flex justify-between items-center mb-4">
        <button onClick={prevSlide} className="p-2 bg-gray-200 rounded-full shadow">
          &lt;
        </button>
        <button onClick={nextSlide} className="p-2 bg-gray-200 rounded-full shadow">
          &gt;
        </button>
      </div>
      <div className="grid grid-cols-5 gap-4">
        {partners.slice(currentIndex, currentIndex + 5).map((partner) => (
          <PartnerCard key={partner.id} partner={partner} displayType="imageOnly" />
        ))}
      </div>
    </div>
  );
};

export default PartnerCarousel;
