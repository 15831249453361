import React from 'react';
import { Product } from '../../../../lib/data';
import SkeletonProduct from '@modules/skeletons/components/skeleton-product';
import { formatCurrency } from "@lib/util/currency-formatter"

const ProductCard: React.FC<Product> = ({ image, productName, lowestPrice }) => {
  if (!image || !productName) {
    return <SkeletonProduct />; // Mostra lo skeleton se i dati sono null
  }

  return (
    <div className="bg-white shadow rounded-lg p-4 flex flex-col items-center">
      <img src={image} alt={productName} className="w-full h-40 object-cover mb-4" />
      <h2 className="text-lg font-semibold">{productName}</h2>
      <p className="text-xl text-gray-800 mt-2">{formatCurrency(lowestPrice, "EUR")}</p>
    </div>
  );
};

export default ProductCard;
