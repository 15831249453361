// app/components/ProductCarousel.tsx
"use client";

import React, { useState, useEffect } from 'react';
import ProductCard from '../productCard';
import SkeletonProduct from '@modules/skeletons/components/skeleton-product';
import { Product } from '@lib/data';

interface ProductCarouselProps {
  products: Product[] | null;
}

const ProductCarousel: React.FC<ProductCarouselProps> = ({ products }) => {
  const [currentIndex, setCurrentIndex] = useState(0);

  const nextSlide = () => {
    setCurrentIndex((prevIndex) => Math.min(prevIndex + 10, products ? products.length - 10 : 0));
  };

  const prevSlide = () => {
    setCurrentIndex((prevIndex) => Math.max(prevIndex - 10, 0));
  };

  if (!products || products.length === 0) {
    // Se non ci sono prodotti o c'è un errore, mostra gli skeletons
    return (
      <div className="grid grid-cols-5 gap-4">
        {Array.from({ length: 10 }).map((_, index) => (
          <SkeletonProduct key={index} />
        ))}
      </div>
    );
  }

  return (
    <div className="relative w-full">
      <div className="flex justify-between items-center mb-4">
        <button onClick={prevSlide} className="p-2 bg-gray-200 rounded-full shadow">
          &lt;
        </button>
        <button onClick={nextSlide} className="p-2 bg-gray-200 rounded-full shadow">
          &gt;
        </button>
      </div>
      <div className="grid grid-cols-5 gap-4">
        {products.slice(currentIndex, currentIndex + 10).map((product, index) => (
          <ProductCard
            key={index}
            image={product.image}
            productName={product.productName}
            lowestPrice={product.lowestPrice}
          />
        ))}
      </div>
    </div>
  );
};

export default ProductCarousel;
