import React from 'react';
import { Partner } from '@/lib/data';
import BannerSkeleton from '@modules/skeletons/components/skeleton-banner';

interface PartnerCardProps {
  partner: Partner;
  displayType?: 'imageOnly' | 'full'; // Aggiungiamo un prop per determinare il tipo di visualizzazione
}

const PartnerCard: React.FC<PartnerCardProps> = ({ partner, displayType = 'full' }) => {
  if (displayType === 'imageOnly') {
    // Se il componente deve visualizzare solo l'immagine
    return partner.imageUrl ? (
      <div className="bg-white shadow rounded-lg p-4 flex flex-col items-center">
        <img src={partner.imageUrl} alt="Partner Logo" className="w-full h-40 object-cover mb-4" />
      </div>
    ) : (
      <BannerSkeleton /> // Mostra lo skeleton se l'immagine è null o non disponibile
    );
  }

  // Visualizzazione completa con tutte le informazioni
  return (
    <div className="flex items-center space-x-4 p-4 border rounded-lg shadow-md">
      <img
        src={partner.image || "/default-image.png"}
        alt={partner.name}
        className="w-16 h-16 object-cover rounded"
      />
      <div className="flex-grow">
        <h2 className="text-lg font-semibold">{partner.name}</h2>
        <p className="text-sm text-gray-500">{partner.prospectTypeName}</p>
      </div>
      <a href={`/partners/${partner.id}`} className="text-red-500 hover:underline">
        Dettagli
      </a>
    </div>
  );
};

export default PartnerCard;
