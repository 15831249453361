import React from "react"

const FilterBarSkeleton = () => {
  return (
    <div className={"mb-6"}>
      <div className="w-full mb-4 flex flex-col md:flex-row justify-between items-center pb-2">
        <div className="h-8 bg-gray-300 rounded w-1/4"></div>
        <div className="flex items-center space-x-2">
          <div className="h-8 bg-gray-300 rounded w-20"></div>
          <div className="h-8 bg-gray-300 rounded w-40"></div>
        </div>
      </div>
      <div
        className="w-full h-10 flex bg-neutral-100 items-center justify-between border border-gray-200 px-2 rounded-sm">
        <div className="flex h-10 items-center text-sm space-x-2">
          <div className="h-8 bg-gray-300 rounded w-32"></div>
          <div className="h-8 bg-gray-200 rounded w-20"></div>
        </div>
        <div className="h-8 bg-gray-300 rounded w-16"></div>
      </div>
    </div>
  )
}

const ProductGridSkeleton = () => {
  return (
    <div className="grid grid-cols-2 small:grid-cols-3 md:grid-cols-4 gap-4">
      {[...Array(24)].map((_, index) => (
        <div key={index} className="border border-gray-200 rounded-lg p-4 bg-gray-100 animate-pulse">
          <div className="h-40 bg-gray-300 rounded mb-4"></div>
          <div className="h-4 bg-gray-300 rounded w-3/4 mb-2"></div>
          <div className="h-3 bg-gray-200 rounded w-1/2 mb-4"></div>
          <div className="h-8 bg-gray-300 rounded w-1/3"></div>
        </div>
      ))}
    </div>
  )
}

const PaginationSkeleton = () => {
  return (
    <div className="mt-6 flex justify-center items-center space-x-2">
      <div className="h-8 w-8 bg-gray-300 rounded"></div>
      <div className="h-8 w-8 bg-gray-300 rounded"></div>
      <div className="h-8 w-8 bg-gray-300 rounded"></div>
    </div>
  )
}

const ProductListSkeleton = () => {
  return (
    <div className="w-full">
      <FilterBarSkeleton />
      <ProductGridSkeleton />
      <PaginationSkeleton />
    </div>
  )
}

export default ProductListSkeleton
