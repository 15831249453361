import React from 'react';

const BannerSkeleton = () => {
  return (
    <div className="relative w-full h-64 bg-gray-300 rounded-lg animate-pulse">
      <div className="absolute inset-0 flex flex-col justify-center items-center text-white p-4">
        <div className="w-1/2 h-8 bg-gray-400 rounded mb-4"></div>
        <div className="w-1/3 h-6 bg-gray-400 rounded"></div>
      </div>
    </div>
  );
};

export default BannerSkeleton;
